import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CartContext } from "../../context/CartContext";
import { useFormik } from "formik";
import Loader from "../form/Loader";
import IconPlus from "../../assets/icon_js/IconPlus";
import IconMinus from "../../assets/icon_js/IconMinus";
import { UPDATE_CART_TIMEOUT } from "../../../config/cart";
import useToastify from "../../hooks/ui/useToastify";
import { isPromoProduct } from "../../utils/money";

function AdjustItem({ cartType, item, isInventoryLow }) {
  const {
    updateCartItem,
    updatingItem,
    removeCartItem,
    setCheckoutLoadingState,
  } = useContext(CartContext);

  const [state, setState] = useState({ quantity: item.quantity, flag: false });
  const [timer, setTimer] = useState(null);
  const { toastMessage } = useToastify();

  // set timer on state change / quantity change via buttons ...
  useEffect(() => {
    if (state.quantity !== item.quantity && state.flag) {
      setUpdateTimer();
    }
  }, [state]);

  // update quantity on tab select realtime ...
  useEffect(async () => {
    setState(() => {
      return { flag: false, quantity: item.quantity };
    });
    await formik.setFieldValue("quantity", item.quantity);
  }, [item]);

  const formik = useFormik({
    initialValues: {
      quantity: item.quantity,
    },
  });

  // timer for calling update api ...
  const setUpdateTimer = () => {
    // check if any timer already exists if yes then discard previous one and create new one ...
    if (timer) {
      clearTimeout(timer);
    }
    const timeOut = setTimeout(() => {
      // remove product from cart when quantity is zero otherwise update line-item..
      if (state.quantity === 0) {
        removeCartItem(item.sku);
      } else {
        updateCartItem(item, parseInt(state.quantity), formik);
      }
    }, UPDATE_CART_TIMEOUT);
    setTimer(timeOut);
  };

  // update increment or decrement state quantity via buttons ...
  const handleQuantityChange = async (value) => {
    setCheckoutLoadingState(true);

    const isValid = validateQnt(
      item.inventory,
      state.quantity,
      value,
      item.order_quantity_minimum,
      item.order_quantity_maximum
    );

    if (isValid) {
      setState((prevState) => {
        return { flag: true, quantity: prevState.quantity + value };
      });
      await formik.setFieldValue("quantity", state.quantity + value);
    }
  };

  const validateQnt = (
    availableInventory,
    quantity,
    updatedQuantity,
    minQuantity,
    maxQuantity
  ) => {
    let isValid = true;

    if (quantity + updatedQuantity < 0) {
      toastMessage("error", `Sorry, you must purchase at least 1 of this item`);
      setState({ flag: false, quantity: 1 });
      isValid = false;
      return isValid;
    }

    // Check if minimum quantity is required and the total quantity falls below it
    if (
      availableInventory >= minQuantity &&
      minQuantity > 0 &&
      quantity + updatedQuantity < minQuantity
    ) {
      toastMessage(
        "error",
        `You have to purchase minimum ${minQuantity} quantities.`
      );
      setState({ flag: false, quantity: quantity });
      isValid = false;
    }

    // Check if maximum quantity is exceeded
    if (maxQuantity > 0 && quantity + updatedQuantity > maxQuantity) {
      toastMessage(
        "error",
        `You can purchase maximum upto ${maxQuantity} quantities.`
      );
      setState({ flag: false, quantity: quantity });
      isValid = false;
    }

    return isValid;
  };

  return (
    <div
      className={`bc-cart-item-quantity cart-col-qty cart-col-5 ${isInventoryLow ? "has-low-inventory" : "has-valid-inventory"
        }`}
    >
      <div className="cart-item-inner">
        <p className="cart-item-lable">Quantity</p>
        <div className="text-center">
          <div className="form-increment">
            {cartType === "full" && (
              <button
                className="button"
                onClick={() => handleQuantityChange(-1)}
                disabled={
                  isPromoProduct(item?.["original_price"]) ||
                  item?.quantity <= item?.order_quantity_minimum ||
                  item?.quantity === 1
                }
              >
                <IconMinus />
              </button>
            )}

            <form onSubmit={formik.handleSubmit}>
              {/* particular any particular line item updating show loader there.. */}
              {updatingItem === item.sku ? (
                <Loader />
              ) : (
                <input
                  type="number"
                  name="quantity"
                  id={item.sku}
                  onWheel={(e) => e.target.blur()}
                  className={
                    isInventoryLow
                      ? "form-input qty-input"
                      : "form-input qty-input"
                  }
                  onChange={formik.handleChange}
                  value={formik.values.quantity}
                  disabled={isPromoProduct(item?.["original_price"])}
                  // while focus removed from the input box immediatly
                  // update cart line item api call will trigger ...
                  onBlur={(e) => {
                    const isValid = validateQnt(
                      item.inventory,
                      0,
                      parseInt(e.target.value),
                      item.order_quantity_minimum,
                      item.order_quantity_maximum
                    );

                    if (isValid) {
                      parseInt(e.target.value) !== state.quantity &&
                        updateCartItem(
                          item,
                          parseInt(e.target.value),
                          formik
                        ).then((res) => {
                          // update local state if line-item updated successfully...
                          if (res) {
                            setState(() => {
                              return {
                                flag: false,
                                quantity: parseInt(e.target.value),
                              };
                            });
                          }
                        });
                    } else {
                      setState({ flag: false, quantity: state?.quantity });
                      formik.setFieldValue("quantity", state?.quantity);
                    }
                  }}
                />
              )}
            </form>

            {cartType === "full" && (
              <button
                className="button"
                onClick={() => handleQuantityChange(1)}
                disabled={
                  isPromoProduct(item?.["original_price"]) ||
                  (item?.quantity >= item?.order_quantity_maximum &&
                    item?.order_quantity_maximum !== 0) ||
                  item?.quantity >= item?.inventory
                }
              >
                <IconPlus />
              </button>
            )}
          </div>
          <small className="cart-invertory-count">{`${item.inventory} Left in stock`}</small>
        </div>
      </div>
    </div>
  );
}

AdjustItem.propTypes = {
  item: PropTypes.object,
  cartType: PropTypes.string,
  isInventoryLow: PropTypes.bool,
};

export default AdjustItem;
