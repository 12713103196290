import { useState } from 'react'
import useToastify from '../ui/useToastify'
import Api from '../../services/Api'
import { saveCartRequestURL } from '../../ApiEndpoints'

function useCartSave() {
  const [isLoading, setLoading] = useState(false)
  // const { user } = useSelector((state) => state.auth)
  const { toastMessage } = useToastify()

  async function saveCart(data) {
    try {
      const url = `${saveCartRequestURL}?email=${data['email']}&notes=${data['notes']}`

      setLoading(true)

      const res = await bcSaveCart(url)

      if (res) {
        toastMessage('success', res['message'])
        setLoading(false)
        return true
      } else {
        toastMessage('error', res['message'])
        setLoading(false)
        return false
      }
    } catch (e) {
      console.error(e)
      setLoading(false)
    }
  }

  return { saveCart: saveCart, savingCart: isLoading }
}

async function bcSaveCart(url) {
  const response = await Api.post(url)
  return response.data
}

export default useCartSave
