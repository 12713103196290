import { RESTRICTED_CUSTOMER_GROUPS } from '../../../config/customerGroups'
import { useSelector } from 'react-redux'
import { intersection } from '../../utils/functions'

function useCustomerGroupRestriction() {
  const { currentLoginUser } = useSelector((state) => state.customer)

  const customerGroupID = currentLoginUser?.customer_group_id

  if (intersection(RESTRICTED_CUSTOMER_GROUPS, [customerGroupID]).length > 0)
    return { isCustomerRestricted: true }
  else return { isCustomerRestricted: false }
}

export default useCustomerGroupRestriction
