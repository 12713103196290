import React from "react";

function ClearCartConfirmModal({ clearCart, onClose }) {
  const onConfirm = () => {
    clearCart();
    onClose();
  };

  return (
    <>
      <p className="text-center">
        You are about to empty your entire cart, this cannot be undone.
      </p>
      <div className="reward-modal-action text-right">
        <span
          className="button button-border button-small"
          onClick={() => onClose()}
          role="button"
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClose();
            }
          }}
        >
          Cancel
        </span>
        <button
          className="button button--secondary button-small"
          onClick={() => onConfirm()}
        >
          Clear Entire cart
        </button>
      </div>
    </>
  );
}

export default ClearCartConfirmModal;
