import React from "react";

function CartHeader() {
  return (
    <header className="bc-cart-header">
      <div className="bc-cart-header__item cart-col-mark cart-col-1">Item</div>
      <div className="bc-cart-header__item cart-col-image cart-col-2"></div>
      <div className="bc-cart-header__item cart-col-name cart-col-3"></div>
      <div className="bc-cart-header__price cart-col-price cart-col-4">Item Price</div>
      <div className="bc-cart-header__qty cart-col-qty cart-col-5">Qty</div>
      <div className="bc-cart-header__price cart-col-tprice cart-col-6">Item Total</div>
    </header>
  );
}

export default CartHeader;
