import React, { Component } from 'react'

export default class IconMinus extends Component {
  render() {
    return (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="720"
        height="1024"
        viewBox="0 0 720 1024"
      >
        <title></title>
        <g id="icomoon-ignore"></g>
        <path
          fill="none"
          strokeLinejoin="miter"
          strokeLinecap="butt"
          strokeMiterlimit="10"
          strokeWidth="81.92"
          stroke="#000"
          d="M32.32 512h655.36"
        ></path>
      </svg>
    )
  }
}
