import React from "react";
import PropTypes from "prop-types";

function CartMessage({ message }) {
  return (
    <div className="bc-cart-error">
      <p className="bc-cart-error__message"></p>
    </div>
  );
}

CartMessage.propTypes = {
  message: PropTypes.string,
};

export default CartMessage;
