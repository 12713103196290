import React from "react";
import Layout from "../layout/Layout";
import Cart from "../sections/cart/Cart";
// import useCheckoutIframe from "../hooks/cart/useCheckoutIframe";
import Helmet from "react-helmet";
import { withPrefix } from "gatsby";

function CartPageTemplate() {
  return (
    <>
      <section className="container" id="cart-container">
        <Cart cartType="full" />
      </section>
    </>
  );
}

function CartPage() {
  return (
    <>
      <Helmet>
        <script src={withPrefix("script.js")} type="text/javascript" />
      </Helmet>
      <CartPageTemplate />
    </>
  );
}
CartPage.Layout = Layout
export default CartPage;
