import React from "react";
import { useForm } from "react-hook-form";
import Button from "../form/button/Button";
import ButtonLoader from "../form/ButtonLoader";
import useCartSave from "../../hooks/cart/useCartSave";

function SaveYourCartForm({ onClose }) {
  const { saveCart, savingCart } = useCartSave();

  const { register, handleSubmit } = useForm({
    initialValues: { email: "", notes: "" },
  });

  return (
    <>
      {/* <h2>Save Your Cart</h2> */}
      <p className="text-center">
        Enter an email address below to share a list of the items currently in
        your cart.
      </p>
      <form
        className="save-cart-form"
        onSubmit={handleSubmit(async (data) => {
          const res = await saveCart(data);
          if (res) {
            onClose();
          }
        })}
      >
        <div className="form-field">
          <label className="form-label" type="text" htmlFor="email">
            Your Email
          </label>
          <input
            id="email"
            className="form-input"
            type="text"
            {...register("email")}
          />
        </div>

        <div className="form-field">
          <label className="form-label" htmlFor="notes">Notes</label>
          <textarea
            id="notes"
            className="form-input"
            name="notes"
            {...register("notes")}
          />
          {/* <p className="small m-b-10">
            <small>Use commas for multiple emails</small>
          </p> */}
        </div>

        {savingCart ? (
          <ButtonLoader />
        ) : (
          <Button variant="secondary" type="submit">
            Save Cart
          </Button>
        )}
      </form>
    </>
  );
}

export default SaveYourCartForm;
